import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import Header from '@components/Header';
import { graphql } from 'gatsby';
import { flatten, compact } from 'lodash';
import { css } from '@emotion/react';
import Footer from '@components/Footer';
import Page, { getPageElements } from '@components/Page';
import { getFirstBlock } from '@lib/blocks';
import Hero from '@components/theme/Hero';
import PricingTable from '@components/theme/PricingTable';
import RangeInput from '@components/theme/RangeInput';
import { getMoney, setRowItemsValue, getInitTierItem, getItemValue, getItemType } from '@lib/pricing';
import FrontpageLink from '@components/FrontpageLink';

export default function PricingTemplate({ data: { page, prices, plan }, pageContext: { language, refs, layouts, configuration }}) {
  const elements = getPageElements(page);
  const money = getMoney({ locale: language.locale, currencyCode: language.currency });
  const planList = getFirstBlock(plan);
  const priceNodes = prices.edges.map(({ node }) => node);
  const products = planList.products.map(product => Object.assign({}, product, {
    prices: priceNodes.filter(price => product.stripeProductId === price.product.id)
  }));
  const { tiers: headers } = planList;
  const initialState = planList.sections.map(section => Object.assign({}, section, { items: [
    getInitTierItem(products, section.key, 'core'),
    getInitTierItem(products, section.key, 'advanced'),
    getInitTierItem(products, section.key, 'premium')
  ] }));
  const [rows, setRows] = useState(initialState);
  const [venues, setVenues] = useState(1);
  const [isFixed, setFixed] = useState(false);
  const sumUp = useMemo(() => [{ title: elements.modalTableCorePlan, value: 1, prices: priceNodes.filter(({ product }) => product.id === 'prod_LHd9JcqnMETFQP') }, ...compact(flatten(rows.map(({ items }) => items)).map(item => item && item.value && getItemValue(item, language, false, 'year', false) > 0 ? item : null))].map((item) => ({
    title: item.title,
    type: getItemType(item, language) === 'recurring' ? 'recurring' : 'one-time',
    quantity: item.value * venues,
    unitPrice: getItemValue(item, language, false, 'year', false),
    totalPrice: venues * getItemValue(item, language, false, 'year', false)
  })), [rows, venues]);
  const totalR = sumUp.filter(({ type }) => type === 'recurring').reduce((value, { totalPrice }) => value + totalPrice, 0);

  const toggleFixed = useCallback(() => {
    let reachFooter = (window.document.querySelector('body').clientHeight - window.innerHeight - window.pageYOffset) <= window.document.querySelector('.Page-Footer').clientHeight;
    if (isFixed && reachFooter)
      setFixed(false);
    if (!isFixed && !reachFooter)
      setFixed(true);
  }, [isFixed]);

  useEffect(() => {
    toggleFixed();
    window.addEventListener('scroll', toggleFixed);
    return () => {
      window.removeEventListener('scroll', toggleFixed);
    };
  }, [toggleFixed]);

  const handleChange = (item, value) =>
    setRows(rows => setRowItemsValue(rows, item, value));

  return (
    <Page>
      <Hero title={elements.heroTitle} subtitle={elements.heroSubtitle} divider={{ variant: 2, reflect: true }}>
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </Hero>
      <div className="position-relative">
        <div className="container mt-2 mb-4">
          <PricingTable
            translations={elements}
            money={money}
            language={language}
            headers={headers}
            rows={rows}
            onChange={handleChange}
          />
          <p className="text-center text-small text-muted">
            <strong>{'* '}</strong>
            <span style={{ fontWeight: 400 }}>{elements.legal}</span>
          </p>
        </div>
        <div
          className="w-100"
          css={css`
          height: 200px;
        `}
        />
        <div
          className={`w-100 position-${isFixed ? 'fixed' : 'absolute'} bg-white`}
          css={css`
          bottom: 0;
          left: 0;
          height: 200px;
          z-index: 2;
        `}
        >
          <div className="container h-100">
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center h-100">
              <div className="row mb-1 w-100">
                <div className="col-md-8 ">
                  <RangeInput
                    label={elements.footerSelectVenues}
                    value={venues}
                    onChange={setVenues}
                  />
                </div>
                <div className="col-md-4">
                  <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center h-100">
                    {venues < 6 ? (
                      <Fragment>
                        <div className="h6 text-muted text-center">{elements.footerTotal}</div>
                        <div className="display-4 text-center mb-2">{money(totalR / 12) }</div>
                        <FrontpageLink className="btn btn-primary" url={elements.urlDemo} text={elements.footerBuy} />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="h6 text-muted text-center">{elements.footerCustom}</div>
                        <FrontpageLink className="btn btn-primary btn-lg" url={elements.urlContact} text={elements.footerContact} />
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
              {venues < 6 && (
                <div className="w-100 text-center">
                  {elements.footerCustom} <FrontpageLink url={elements.urlContact} text={elements.footerContact} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String, $pricingId: String) {
    prices: allStripePrice( filter: { active: { eq: true } } sort: { fields: [unit_amount] } ) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          type
          tiers_mode
          recurring {
            interval
            interval_count
            usage_type
          }
          tiers {
            unit_amount
            unit_amount_decimal
            up_to
          }
          product {
            id
            name
          }
        }
      }
    }
    pricingBG: file(relativePath: { eq: "pricing-bg.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
    plan: wpCustomPage(id: { eq: $pricingId }) {
      id
      language {
        code
      }
      block {
        block {
          ... on WpCustomPage_Block_Block_PlanListSection {
            products {
              description
              section
              tier
              stripeProductId
              title
              type
              link {
                text: linkText
                url: linkUrl
                style: linkStyle
              }
            }
            sections {
              description
              key
              title
              image {
                localFile {
                  sharp: childImageSharp {
                    fixed {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            tiers {
              description
              key
              title
            }
            planNegotiableOffer
            plans {
              link {
                linkStyle
                linkText
                linkUrl
              }
              planBillingCycle
              planCurrency
              planFeatures {
                feature
              }
              planName
              planPrice
            }
          }
        }
      }
    }
  }
`;