import { dinero, toFormat } from 'dinero.js';
import * as currencies from '@dinero.js/currencies';
import { minBy, compact, flatten, kebabCase } from 'lodash';

function intlFormat(dineroObject, locale, options = {}) {
  return toFormat(dineroObject, ({ amount, currency }) =>
    amount.toLocaleString(locale, Object.assign({}, options, {
      style: 'currency',
      currency: currency.code
    }))
  );
}

export function getMoney({ locale, currencyCode, maximumFractionDigits, minimumFractionDigits }) {
  return (amount) => money({ amount, locale, currencyCode, maximumFractionDigits, minimumFractionDigits });
}

export function money({ amount, locale, currencyCode, maximumFractionDigits = 0, minimumFractionDigits = 0 }) {
  let dineroObject = dinero({ amount, currency: currencies[currencyCode.toLocaleUpperCase()] });
  return intlFormat(dineroObject, kebabCase(locale), { maximumFractionDigits, minimumFractionDigits });
}

export const setRowItemsValue = (rows, item, value) =>
  rows.map(row => row.key === item.section ?
    Object.assign({}, row, {
      items: row.items.map(rowItem => {
        if (!rowItem) return rowItem;
        return rowItem.tier === item.tier ?
          Object.assign({}, rowItem, { value: rowItem.type === 'select' ? 1 : value }) :
          rowItem.type === 'select' ?
            Object.assign({}, rowItem, { value: 0 }) : rowItem;
      })
    }) : row
  );

export const getInitTierItem = (items, section, tier) => {
  let item = items.find(item => item.section === section && item.tier === tier);
  if (!item) return item;
  let value = tier === 'core' && item.type === 'select' ? 1 : 0;
  return Object.assign({}, item, { value });
};

export const getItemValue = (item, language, display = false, interval = 'month', recurringOnly = false) => {
  if (!item) return 0;
  let quantity = item.type === 'select' && display ? 1 : parseInt(item.value);
  let price = item.prices && item.prices.find(({ currency, recurring }) => language.currency === currency && (recurring ? recurring.interval === interval : !recurringOnly));
  return price ?
    price.tiers ?
      minBy(price.tiers.filter(({ up_to }) => quantity <= (up_to === null ? Number.POSITIVE_INFINITY : up_to)), ({ up_to }) => up_to === null ? Number.POSITIVE_INFINITY : up_to).unit_amount * quantity :
      price.unit_amount * quantity
    : 0;
};

export const getItemType = (item, language) => {
  if (!item) return 0;
  let price = item.prices && item.prices.find(({ currency }) => language.currency === currency);
  return price ? price.type : null;
};

export const getStripePrices = (rows, language, venues, corePrice) => {
  let core = {
    quantity: parseInt(venues),
    price: corePrice.id
  };
  let lineItems = compact(compact(flatten(rows.map(({ items }) => items))).filter(({ prices }) => prices).map(item => {
    let quantity = parseInt(item.value);
    let price = item.prices && item.prices.find(({ currency, recurring }) => language.currency === currency && (recurring ? recurring.interval === 'year' : true));
    return price && quantity ? {
      quantity: parseInt(venues) * quantity,
      price: price.id
    } : null;
  }));
  lineItems.unshift(core);
  return lineItems;
};