import React from 'react';
import { times } from 'lodash';

export default function RangeInput({ id = 'RangeInput', label, value, onChange, min = '1', max = '6', step = '1' }) {
  const handleChange = event => {
    event.preventDefault();
    onChange(event.target.value);
  };
  return (
    <div className='w-100'>
      <div>
        {label && (
          <label className="d-block h5 text-center" htmlFor={id}>{label}</label>
        )}
        <input type="range" className="custom-range" id={id} min={min} max={max} step={step} value={value} onChange={handleChange} />
      </div>
      <div className="custom-range-labels">
        {times((parseInt(max) / parseInt(step)) - 1, index => (
          <span key={`index-${index}`}>{(index + 1) * parseInt(step)}</span>
        ))}
        <span>{'6+'}</span>
      </div>
    </div>
  );
}