import React, { Fragment } from 'react';
import GatsbyImage from 'gatsby-image';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import NumberInput from '@components/ui/NumberInput';
import RangeSelect from '@components/ui/RangeSelect';
import { getItemValue } from '@lib/pricing';
import { Link } from 'gatsby';

library.add(faCheck);

const FaCheckbox = ({ checked, onChange }) => {
  const handleClick = (event) => {
    event.preventDefault();
    onChange(!checked);
  };
  return (
    <a href="#" onClick={handleClick} className={`btn-${checked ? 'success' : 'light'} btn-round btn-lg position-absolute`} style={{ top: -10, right: -10 }}>
      <FontAwesomeIcon
        icon={faCheck}
      />
    </a>
  );
};

export default function PricingTable({ headers, rows, language, onChange, money, translations }) {
  const handleChange = item => value => onChange(item, value);

  return (
    <table className="table table-hover pricing-table table-responsive-md">
      <thead>
        <tr>
          <th scope="col"></th>
          {headers.map(({ title, description }) => (
            <th scope="col" key={title}>
              <div className="h4">{title}</div>
              <div className="text-small text-muted">{description}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map(({ title, description, image, items }) => (
          <tr key={title}>
            <th scope="row">
              <span className="media">
                <GatsbyImage
                  className="mr-2"
                  alt={title}
                  fixed={image.localFile.sharp.fixed}
                />
                <span className="media-body">
                  <span className="h6 mb-1 d-block">{title}</span>
                  <span className="text-small text-muted">{description}</span>
                </span>
              </span>
            </th>
            {items.map((item, index) => {
              let price = item && item.prices && item.prices.find(({ currency, type, recurring }) => language.currency === currency && type === 'one_time' ? true : recurring && recurring.interval === 'year');
              return (
                <td className="pricing-table-item position-relative overflow-hidden" key={`${title}-${index}`}>
                  {item && (
                    <Fragment>
                      {item.type === 'select' && (
                        <FaCheckbox checked={!!item.value} onChange={handleChange(item)} />
                      )}
                      {(item.type === 'display') && (
                        <span className="mb-1 d-inline-block badge badge-light">
                          {translations.labelCustom}
                        </span>
                      )}
                      {(item.type === 'select' || item.type === 'free' || item.type === 'quantity' || item.type === 'range') && (
                        <span className="mb-1 d-inline-block badge badge-secondary">
                          {price ? `+ ${money(price.type === 'one_time' ? getItemValue(item, language, true, 'year') : getItemValue(item, language, true, 'year') / 12)} ${price.type === 'recurring' ? `/ ${translations.labelMonth}` : translations.labelOneTime}` : translations.labelIncluded}
                        </span>
                      )}
                      <span className="mb-1 d-block">{item.title}</span>
                      {item.type === 'quantity' && (
                        <NumberInput value={item.value} onChange={handleChange(item)} />
                      )}
                      {item.type === 'range' && (
                        // TODO: inspect the Stripe price model
                        // <RangeSelect language={language} price={price} value={item.value} onChange={handleChange(item)} />
                        null
                      )}
                      {item.description && (
                        <span className="text-small text-muted font-weight-normal">{item.description}</span>
                      )}
                      {item.link && item.link.text && (
                        <Link className="d-block mt-1 text-small font-weight-normal" to={item.link.url}>{item.link.text}</Link>
                      )}
                    </Fragment>
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}