import React from 'react';

export default function NumberInput({ value = 1, onChange }) {

  const handleAdd = event => {
    event.preventDefault();
    onChange(value + 1);
  };

  const handleSubstract = event => {
    event.preventDefault();
    if (value - 1 <= 0)
      return onChange(0);
    onChange(value - 1);
  };

  const handleChange = event => {
    event.preventDefault();
    if (value <= 0)
      return onChange(0);
    onChange(event.target.value);
  };

  return (
    <div className="input-group input-group-sm">
      <div className="input-group-prepend">
        <button onClick={handleSubstract} className="btn btn-light" type="button" id="button-addon1">{'-'}</button>
      </div>
      <input type="text" className="form-control text-center" value={value} onChange={handleChange} />
      <div className="input-group-append">
        <button onClick={handleAdd} className="btn btn-light" type="button" id="button-addon2">{'+'}</button>
      </div>
    </div>
  );
}